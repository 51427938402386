<template>
  <div class="page-container">
    <el-card class="box-card" :body-style="{ flex: 1, overflow: 'auto' }">
      <template #header>
        <div class="card-header">
          <span>展后编辑</span>
          <div>
            <el-button class="button" type="danger" size="small" @click="cancelEdit">返回</el-button>
            <el-button class="button" type="primary" size="small" @click="saveEdit">保存</el-button>
          </div>
        </div>
      </template>
      <el-form ref="form" class="dialog-form" :model="detailform" label-width="120px">
        <el-form-item label="标题名称：">
          {{detailform.name}}
        </el-form-item>
        <el-form-item label="类型：">
          {{detailform.type}}
        </el-form-item>
        <el-form-item label="日期：">
          {{detailform.startDate + ' 至 ' + detailform.endDate}}
        </el-form-item>
        <el-form-item label="时间：">
          {{(detailform.startTime||'') + ' 至 ' + (detailform.endTime||'')}}
        </el-form-item>
        <el-form-item label="地点：">
          {{detailform.location}}
        </el-form-item>
        <el-form-item label="展后内容：">
          <div class="el-editor" style="">
            <Editor ref="activeEditor" v-model="detailform.content" />
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "vue"
import { useRouter } from 'vue-router'

import { ElMessage } from "element-plus";
// import { formatTime } from "../../common/utils";
import Editor from '@/components/editor'
import { getUrlParam } from '@/common/utils';

export default {
  components: {
    Editor
  },
  mixins: [common],
  setup() {
    const router = useRouter()
    const activityId = getUrlParam(window.location.href, 'id')
    const state = reactive({
      activeEditor: null,
      detailform: {
        id: activityId,
        name: '',
        type: '',
        icon: '',
        location: '',
        content: '', // 内容
        poster: '', // 海报
        unionCustomers: [],
        unionPicture: ''
      }
    })
    const getData = async () => {
      try {
        const res = await request("getReviewDetail", {
          id: activityId
        })
        console.log('getActivityDetail', res)
        state.detailform = res
      } catch (error) {
        console.error(error)
      }
    }
    const saveEdit = async () => {
      if (!state.detailform.content) {
        ElMessage({
          showClose: true,
          type: "warning",
          message: "展后内容不能为空!"
        })
        return
      }
      try {
        await request('editReviewDetail', {
          activityId: activityId,
          reviewContent: state.detailform.content
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "保存成功!"
        })
        router.back()
      } catch (err) {
        console.error(err)
      }
    }
    const cancelEdit = () => {
      console.log('cancelEdit')
      router.back()
      // state.activeEditor.getContent()
    }
    getData()
    return {
      ...toRefs(state),
      saveEdit,
      cancelEdit,
    };
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.box-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 10;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>