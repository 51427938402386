<template>
  <div class="page-container">
    <Table ref="tableRef" :params="params" :request="getData" tooltip-effect="light">
      <template #search="{query}">
        <el-form-item>
          <el-button type="primary" plain @click="exportData(query)">导出</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 20px;" label="待发放金额：">{{ notSendAmount }}元</el-form-item>
      </template>
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <!-- <el-table-column prop="name" label="活动名称" /> -->
      <el-table-column prop="activityType" label="活动类型" >
        <template #default="{row}">{{activityTypeMap[row.activityType]}}</template>
      </el-table-column>
      <el-table-column prop="userId" label="用户ID" />
      <el-table-column prop="joinTime" label="参与时间" sortable="custom" />
      <el-table-column prop="userName" label="用户姓名" />
      <el-table-column prop="userPhone" label="手机号" />
      <el-table-column prop="redAmount" label="红包金额" />
      <el-table-column prop="orderNo" label="支出流水号" />
      <el-table-column prop="redEnvelope" label="发放状态" >
        <template #default="{row}">{{ packetStatusMap[row.redEnvelope] }}</template>
      </el-table-column>
      <!-- <el-table-column prop="company" label="公司" /> -->
      <!-- <el-table-column prop="companyApplication" label="应用公司" /> -->
      <el-table-column prop="activityContent" label="参与内容" >
        <template #default="{row}" >
          <el-button v-if="row.verifyStatus !== 3" type="text" plain size="mini" @click="packetDetail(row)">详情</el-button>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="" label="点赞/收藏/评论" /> -->
      <el-table-column prop="robotNum" label="机器人编码" />
      <el-table-column prop="applicationType" label="应用类型" />

      <el-table-column prop="isOpen" label="是否公开" >
        <template #default="{row}">{{ row.isOpen?'不公开':'公开' }}</template>
      </el-table-column>
      <el-table-column prop="verifyContext" label="审批意见" />
      <el-table-column prop="verifyStatus" label="审批状态" >
        <template #default="{row}">{{ verifyStatusMap[row.verifyStatus] }}</template>
      </el-table-column>
      <el-table-column prop="verifyUser" label="审批人" />
      <el-table-column label="操作"  width="220" fixed="right">
        <template #default="{row}">
          <el-button v-if="!row.verifyStatus" type="primary" plain size="mini" @click="packetApprove(row)">通过</el-button>
          <el-button v-if="!row.verifyStatus" type="warning" plain size="mini" @click="packetReject(row)">拒绝</el-button>
          <el-button v-if="!row.verifyStatus" type="danger" plain size="mini" @click="packetDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog v-model="dialogDataVisible" top="5vh" width="75%" title="详情" destroy-on-close :modal="true" :append-to-body="true">
      <el-form class="dialog-form" label-width="120px">
        <el-form-item class="list-item" label="时间：">
          <div>{{ detailData.joinTime }}</div>
        </el-form-item>
        <el-form-item class="list-item" label="姓名：">
          <div>{{ detailData.userName }}</div>
        </el-form-item>
        <el-form-item class="list-item" label="内容：">
          <div class="form-textarea">
            {{ detailData.activityContent }}
          </div>
        </el-form-item>
        <el-form-item class="list-item" label="上线前图片/视频：">
          <template
            v-for="(file, index) in (detailData.activityFileFront||'').split(',')"
            :key="index"
          >
            <video v-if="file && file.includes('.mp4')" class="el-video-inline" controls>
              <source :src="file" />
            </video>
            <el-image
              v-else-if="file"
              class="el-image-inline"
              :src="file"
              :preview-src-list="(detailData.activityFileFront||'').split(',')"
            >
            </el-image>
          </template>
        </el-form-item>
        <el-form-item class="list-item" label="上线后图片/视频：">
          <template
            v-for="(file, index) in (detailData.activityFileAfter||'').split(',')"
            :key="index"
          >
            <video v-if="file && file.includes('.mp4')" class="el-video-inline" controls>
              <source :src="file" />
            </video>
            <el-image
              v-else-if="file"
              class="el-image-inline"
              :src="file"
              :preview-src-list="(detailData.activityFileAfter||'').split(',')"
            >
            </el-image>
          </template>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { ElMessage, ElMessageBox } from "element-plus";
import Table from "@/components/elp/table";

import { request, downloadFile } from "@/utils/request";
import { common } from "@/common/mixin";
import { verifyStatusList, verifyStatusMap, packetStatusList, packetStatusMap } from "@/common/constant"
import { onMounted } from '@vue/runtime-core';

export default {
  components: {
    Table,
  },
  mixins: [common],
  setup() {
    const activityTypeList = [
      { name: "新机上线", value: 0 },
      { name: "注册领红包", value: 1 },
    ]
    const activityTypeMap = {
      0: '新机上线',
      1: '注册领红包'
    }
    const state = reactive({
      tableRef: null,
      params: [
        {
          type: "Input",
          key: "userName",
          value: "",
          label: "领取人",
        },
        {
          type: "Input",
          key: "userPhone",
          value: "",
          label: "手机号",
        },
        {
          type: "Select",
          key: "activityType",
          value: "",
          label: "活动类型",
          options: {
            data: activityTypeList,
            label: "name",
            value: "value"
          }
        },
        {
          type: "Input",
          key: "robotNum",
          value: "",
          label: "机器人编码",
        },
        {
          type: "Select",
          key: "verifyStatus",
          value: "",
          label: "审批状态",
          options: {
            data: verifyStatusList,
            label: "name",
            value: "value"
          }
        },
        {
          type: "Select",
          key: "redEnvelope",
          value: "",
          label: "发放状态",
          options: {
            data: packetStatusList,
            label: "name",
            value: "value"
          }
        },
        {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "参与时间",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        },
      ],
      dialogDataVisible: false,
      detailData: {},
      activityType: activityTypeList, // 活动类型列表
      notSendAmount: ''
    })
    const getData = (params) => {
      const query = {
        pageNum: params.page,
        pageSize: params.size,
      }
      if (params.dateRange?.length) {
        query.startDate = params.dateRange[0]
        query.endDate = params.dateRange[1]
      } else {
        query.startDate = undefined
        query.endDate = undefined
      }
      return request("packetJoinList", Object.assign(query, params))
    };
    const getRedAmountCount = async () => {
      try {
        state.notSendAmount = await request("getRedAmountCount", {});
      } catch (error) {
        console.error(error);
      }
    }
    const packetDetail = (row) => {
      state.dialogDataVisible = true
      state.detailData = row
    }
    const exportData = async (params={}) => {
      try {
        const query = { ...params }
        if (params.dateRange?.length) {
          query.startDate = params.dateRange[0]
          query.endDate = params.dateRange[1]
        } else {
          query.startDate = undefined
          query.endDate = undefined
        }
        const res = await downloadFile("exportPacketJoin", {}, {
          method: 'GET',
          params: query
        });
        // let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '参与记录.xlsx'
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
    const packetApprove = async (row) => {
      try {
        await ElMessageBox.confirm('审批通过？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success',
        })
        // const { value } = await ElMessageBox.prompt(`请输入审批意见`, {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   inputValue: '',
        //   inputType: 'text',
        //   inputPattern: /^[^\s]+$/,
        //   inputErrorMessage: '输入无效'
        // })
        
        await request("packetApprove", {
          id: row.id
        });
        const imgList = []
        const video = []
        // if (row.activityFileFront?.includes('.mp4')) {
        //   video.push(row.activityFileFront)
        // } else if (row.activityFileFront) {
        //   imgList.push(...row.activityFileFront.split(','))
        // }
        if (row.activityFileAfter?.includes('.mp4')) {
          video.push(row.activityFileAfter)
        } else if (row.activityFileAfter) {
          imgList.push(...row.activityFileAfter.split(','))
        }
        // 公开 添加到技术论坛
        !row.isOpen && await request("addForum", {
          userId: row.userId,
          title: row.applicationType,
          content: row.activityContent,
          attachments: imgList.join(',') + ';;' + video.join(','),
          postType: 0
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
        state.tableRef?.update()
      } catch (error) {
        console.error(error);
      }
    }
    const packetReject = async (row) => {
      try {
        const { value } = await ElMessageBox.prompt(`请输入审批意见`, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // inputValue: '',
          inputType: 'text',
          inputPattern: /^[^\s]+$/,
          inputErrorMessage: '输入无效'
        })

        await request("packetReject", {
          id: row.id,
          verifyContext: value
        });
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
        state.tableRef?.update()
      } catch (error) {
        console.error(error);
      }
    }
    
    const packetDelete = async (row) => {
      try {
        await ElMessageBox.confirm('确定删除？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        await request("packetDelete", {
          id: row.id,
        });
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
        state.tableRef?.update()
      } catch (error) {
        console.error(error);
      }
    }

    onMounted(() => {
      getRedAmountCount()
    })
    return {
      verifyStatusMap,
      activityTypeMap,
      packetStatusMap,
      ...toRefs(state),
      getData,
      exportData,
      packetDetail,
      packetApprove,
      packetReject,
      packetDelete
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.list-item {
  margin-bottom: 0;
  text-align: left;
}
</style>
