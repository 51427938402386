import { ref, onMounted } from 'vue'
import { request } from "@/utils/request";

export default function () {
  const activityTypeList = ref([])
  const activityStatusList = ref([])

  const getActivityType = async () => {
    try {
      const res = await request("activityType")
      activityTypeList.value = res || []
    } catch (error) {
      console.error(error)
    }
  }
  const getActivityStatus = async () => {
    try {
      const res = await request("activityStatus")
      activityStatusList.value = res || []
    } catch (error) {
      console.error(error)
    }
  }
  onMounted(() => {
    getActivityType()
    getActivityStatus()
  })

  return {
    activityTypeList,
    activityStatusList
  }
}