<template>
  <div class="page-container">
    <Table ref="tableRef" :params="params" :request="getData" tooltip-effect="light">
      <template #search="{query}">
        <el-form-item>
          <el-button type="primary" plain @click="exportData(query)">导出</el-button>
        </el-form-item>
      </template>
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <el-table-column prop="receiveTime" label="领取时间" sortable="custom" />
      <el-table-column prop="userName" label="领取人" />
      <el-table-column prop="userId" label="领取人ID" />
      <el-table-column prop="redAmount" label="领取金额" sortable="custom" />
      <el-table-column prop="userPhone" label="手机号" />
      <el-table-column prop="activityType" label="支出活动" >
        <template #default="{row}">{{activityTypeMap[row.activityType]}}</template>
      </el-table-column>
      <el-table-column prop="orderNo" label="支出流水号" />
      <el-table-column prop="robotNum" label="机器人编码" />
      <el-table-column prop="customerName" label="发放状态" >
        <template #default="{row}">{{ packetStatusMap[row.redEnvelope] }}</template>
      </el-table-column>
      <el-table-column prop="verifyTime" label="审核时间" sortable="custom" />
      <el-table-column prop="sendTime" label="发放时间" sortable="custom" />
      <!-- <el-table-column prop="dataType" label="数据类型" >
        <template #default="{row}">{{ row.dataType == 1?'自动生成':'虚拟' }}</template>
      </el-table-column> -->
    </Table>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { ElMessage, ElMessageBox } from "element-plus";
import Table from "@/components/elp/table";

import { request, downloadFile } from "@/utils/request";
import { common } from "@/common/mixin";
import { packetStatusMap } from "@/common/constant"

export default {
  components: {
    Table,
  },
  mixins: [common],
  setup() {
    const activityTypeList = [
      { label: "新机上线", value: 0 },
      { label: "注册领红包", value: 1 },
    ]
    const activityTypeMap = {
      0: '新机上线',
      1: '注册领红包'
    }
    const state = reactive({
      tableRef: null,
      dialogFormVisible: false,
      detailform: {
        robotNum: '',
        cabinetNum: '',
        userId: '',
        userName: '',
        redAmount: '',
      },
      detailformRules: {},
      params: [
        {
          type: "Input",
          key: "userName",
          value: "",
          label: "领取人",
        },
        {
          type: "Input",
          key: "userPhone",
          value: "",
          label: "手机号",
        },
        {
          type: "Select",
          key: "activityType",
          value: "",
          label: "活动类型",
          options: {
            data: activityTypeList,
            label: "label",
            value: "value"
          }
        },
        {
          type: "Input",
          key: "robotNum",
          value: "",
          label: "机器人编码",
        },
        {
          type: "Date",
          key: "dateRangeReceive",
          value: [],
          label: "领取时间",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            // "placeholder": "开始日期",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        },
        {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "审核时间",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            // "placeholder": "开始日期",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        },
        {
          type: "Date",
          key: "dateRangeSend",
          value: [],
          label: "发放时间",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            // "placeholder": "开始日期",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        },
        // {
        //   type: "Select",
        //   key: "dataType",
        //   value: "",
        //   label: "数据类型",
        //   options: {
        //     data: [{name: '自动生成', value: 1}, {name: '虚拟', value: 2}],
        //     label: "name",
        //     value: "value"
        //   }
        // },
      ],
      activityType: activityTypeList // 活动类型列表
    })
    const getData = (params={}) => {
      const query = {
        pageNum: params.page,
        pageSize: params.size,
      }

      // 领取时间
      if (params.dateRangeReceive?.length) {
        query.receiveStartTime = params.dateRangeReceive[0]
        query.receiveEndTime = params.dateRangeReceive[1]
      } else {
        query.receiveStartTime = undefined
        query.receiveEndTime = undefined
      }
      // 审核时间
      if (params.dateRange?.length) {
        query.startDate = params.dateRange[0]
        query.endDate = params.dateRange[1]
      } else {
        query.startDate = undefined
        query.endDate = undefined
      }
      // 发放时间
      if (params.dateRangeSend?.length) {
        query.sendStartTime = params.dateRangeSend[0]
        query.sendEndTime = params.dateRangeSend[1]
      } else {
        query.sendStartTime = undefined
        query.sendEndTime = undefined
      }
      
      return request("packetReceiveList", Object.assign(query, params));
    };
    const addNew = () => {
      state.dialogFormVisible = true
    }
    const exportData = async (params={}) => {
      try {
        const query = { ...params }
        query.page = undefined
        query.size = undefined
        // 领取时间
        if (params.dateRangeReceive?.length) {
          query.receiveStartTime = params.dateRangeReceive[0]
          query.receiveEndTime = params.dateRangeReceive[1]
        } else {
          query.receiveStartTime = undefined
          query.receiveEndTime = undefined
        }
        // 审核时间
        if (params.dateRange?.length) {
          query.startDate = params.dateRange[0]
          query.endDate = params.dateRange[1]
        } else {
          query.startDate = undefined
          query.endDate = undefined
        }
        // 发放时间
        if (params.dateRangeSend?.length) {
          query.sendStartTime = params.dateRangeSend[0]
          query.sendEndTime = params.dateRangeSend[1]
        } else {
          query.sendStartTime = undefined
          query.sendEndTime = undefined
        }
        const res = await downloadFile("exportPacketReceive", {}, {
          method: 'GET',
          params: query
        });
        // let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '红包记录.xlsx'
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
    const editDetail = async () => {
      state.dialogFormVisible = true
    }
    const deleteDetail = async (row) => {
      try {
        ElMessageBox({
          title: '提示',
          message: '确定删除？',
          type: 'warning',
          showCancelButton: 'true',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        await request("", {
          id: row.id
        })
        state.tableRef?.update()
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
      } catch (error) {
        console.error(error);
      }
    }
    const formSubmit = async () => {
      try {
        await request("packetReceiveSave", state.detailform)
        ElMessage({
          showClose: true,
          type: "success",
          message: "发布成功!"
        })
        state.dialogFormVisible = false
        state.tableRef?.update()
      } catch (error) {
        console.error(error)
      }
    }

    return {
      activityTypeMap,
      packetStatusMap,
      ...toRefs(state),
      getData,
      addNew,
      exportData,
      editDetail,
      deleteDetail,
      formSubmit
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
