<template>
  <div class="page-container">
    <el-card class="box-card" :body-style="{ flex: 1, overflow: 'auto' }">
      <template #header>
        <div class="card-header">
          <span>子活动编辑</span>
          <div>
            <el-button class="button" type="danger" size="small" @click="cancelEdit">返回</el-button>
            <el-button v-if="true || detailform.status!=='已结束'" class="button" type="primary" size="small" @click="saveEdit">保存</el-button>
          </div>
        </div>
      </template>
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="120px">
        <el-form-item label="主活动：">
          {{ parentData.name }}
        </el-form-item>
        <el-form-item label="名称：" prop="name">
          <el-input v-model="detailform.name" clearable placeholder="标题名称"></el-input>
        </el-form-item>
        <el-form-item label="地点：" prop="location">
          <el-input v-model="detailform.location" clearable placeholder="地点"></el-input>
        </el-form-item>
        <el-form-item label="日期：" prop="startDate">
          <el-date-picker
            v-model="activityDate"
            type="daterange"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :disabled-date="disabledDate"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时间：" prop="startTime">
          <el-time-picker
            v-model="activityTime"
            is-range
            format="HH:mm:ss"
            value-format="HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            clearable
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="活动标题图：" prop="picture">
          <Upload
            v-model="detailform.picture"
            list-type="picture-card"
            type="image"
            :auto-upload="true"
            :before-upload="beforeUpload"
            :file-list="pictureList"
          >
            <el-button type="text">点击上传图片</el-button>
            <template #tip>
              <div class="el-upload__tip">
                数量1张，大小不超过 500KB，
                <span style="color:red;">图片尺寸：660*360</span>
              </div>
            </template>
          </Upload>
        </el-form-item>
        <el-form-item label="活动内容：" prop="content">
          <div class="el-editor" style="">
            <Editor ref="activeEditor" v-model="detailform.content" />
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs, watch, onMounted } from "vue"
import { useRouter, useRoute } from 'vue-router'

import { ElMessage } from "element-plus";
// import { formatTime } from "../../common/utils";
import Editor from '@/components/editor'
import { urlMap } from '@/common/config';
import { uploadFile } from "@/utils/request";
import Upload from '@/components/upload'
import dayjs from 'dayjs'

export default {
  components: {
    Editor,
    Upload
  },
  mixins: [common],
  setup() {
    const router = useRouter()
    const route = useRoute()
    const parentId = route.query.parentId
    const activityId = route.query.id
    const state = reactive({
      activeEditor: null,
      parentData: {},
      detailData: {},
      detailformRef: null,
      detailform: {
        parentId: parentId,
        id: activityId,
        name: '',
        // type: '',
        picture: '',
        location: '',
        startDate: '',
        endDate: '',
        content: '', // 内容
        status: '',
      },
      activityDate: [],
      activityTime: [],
      upload: null,
      uploadRef: null,
      iconList: [],
      pictureList: [],
      posterList: [],
      unionPictureList: [],
      uploadFile: urlMap['uploadFile'],
      companyList: []
    })
    watch(
      [
        () => state.activityDate,
        () => state.activityTime,
      ],
      (newValue) => {
        console.log('watch', newValue)
        if (state.activityDate?.length) {
          state.detailform.startDate = state.activityDate[0]
          state.detailform.endDate = state.activityDate[1]
        } else {
          state.detailform.startDate = ''
          state.detailform.endDate = ''
        }
        if (state.activityTime?.length) {
          state.detailform.startTime = state.activityTime[0]
          state.detailform.endTime = state.activityTime[1]
        } else {
          state.detailform.startTime = ''
          state.detailform.endTime = ''
        }
      }
    )
    const detailformRules = reactive({
      name: {
        required: true,
        message: '活动标题不能为空',
        trigger: 'blur',
      },
      location: {
        required: true,
        message: '活动地址不能为空',
        trigger: 'blur',
      },
      startDate: {
        required: true,
        message: '活动日期不能为空',
        trigger: 'change',
      },
      startTime: {
        required: true,
        message: '活动时间不能为空',
        trigger: 'change',
      },
      picture: {
        required: false,
        message: '活动标题图不能为空',
        trigger: 'blur',
      },
      content: {
        required: true,
        message: '活动内容不能为空',
        trigger: 'blur',
      },
    })

    const getData = async () => {
      try {
        if (!activityId) return
        const res = await request("getActivityDetail", {
          id: activityId
        })
        state.detailData = res
        console.log('getActivityDetail', res)
        state.activityDate = res.startDate && res.endDate ? [res.startDate, res.endDate]:[]
        state.activityTime = res.startTime && res.endTime ? [res.startTime, res.endTime]:[]

        state.companyList = res.unionCustomers || []
        res.unionCustomers = state.companyList.map((item) => {
          return item.id
        })
        for (let key of Object.keys(state.detailform)) {
          state.detailform[key] = res[key]
        }
        state.pictureList = state.detailform.picture ? [{name: 'picture', url: state.detailform.picture}]:[]
      } catch (error) {
        console.error(error)
      }
    }
    const getParent = async () => {
      try {
        if (!parentId) return
        const res = await request("getActivityDetail", {
          id: parentId
        })
        state.parentData = res
        if (!activityId) {
          state.activityDate = res.startDate && res.endDate ? [res.startDate, res.endDate]:[]
          state.activityTime = res.startTime && res.endTime ? [res.startTime, res.endTime]:['08:00:00', '17:00:00']
        }
        console.log('getParent -->', dayjs(state.parentData.startDate))
      } catch (error) {
        console.error(error)
      }
    }
    const companyQuery = async (name) => {
      try {
        if (!name) return
        const res = await request('getCustomerList', {
          customerName: name
        })
        state.companyList = res.slice(0, 30)
      } catch (error) {
        console.error(error)
      }
    }
    const beforeUpload = (file) => {
      if (file.size > 500000) {
        ElMessage({
          showClose: true,
          type: "warning",
          message: "图片不能大于500KB"
        })
        return false
      } else {
        return true
      }
    }
    const saveEdit = async () => {
      console.log(state.detailform)
      try {
        const url = state.detailform.id ? 'editActivity' : 'saveActivity'
        state.detailformRef && await state.detailformRef.validate()
        console.log('saveEdit validate -->')
        await request(url, state.detailform)
        ElMessage({
          showClose: true,
          type: "success",
          message: "保存成功!"
        })
        router.back()
      } catch (err) {
        console.error(err)
      }
    }
    const cancelEdit = () => {
      router.back()
      // state.activeEditor.getContent()
    }
    function uploadImg (params) {
      console.log('uploadImg', params)
      const formData = new FormData();
      formData.append('multipartFile', params.file)
      uploadFile(formData)
      .then((res) => {
        console.log('images_success', res)
        
      })
      .catch((err) => {
        console.log('images_err', err)
        
      })
    }
    const disabledDate = (time) => {
      return time.getTime() < dayjs(state.parentData.startDate) || time.getTime() > dayjs(state.parentData.endDate)
    }
    onMounted(() => {
      getData()
      getParent()
    })
    return {
      activityId,
      detailformRules,
      getData,
      ...toRefs(state),
      companyQuery,
      saveEdit,
      cancelEdit,
      beforeUpload,
      uploadImg,
      disabledDate
    };
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.box-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 10;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>