<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData">
      <template #handle>
        <el-form-item>
          <el-button type="primary" plain @click="exportData">导出</el-button>
        </el-form-item>
      </template>
    </Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="createTime" label="登记时间" />
      <el-table-column prop="phoneNumber" label="手机号">
        <template #default="{row}">
          {{ row.phoneNumber &&  (String(row.phoneNumber).slice(0,3)+'****'+String(row.phoneNumber).slice(7))}}
        </template>
      </el-table-column>
      <el-table-column prop="userEmail" label="邮箱" />
      <el-table-column prop="company" label="公司" />
      <el-table-column prop="type" label="活动类型" />
      <el-table-column prop="name" label="活动名称" />
      <el-table-column label="活动时间" >
        <template #default="{row}">{{ row.startDate }} - {{ row.endDate }}</template>
      </el-table-column>
      <el-table-column prop="saleName" label="销售人员" />
    </Table>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
// import { ElMessage } from "element-plus";

import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import useActivity from './useActivity';

import { request, downloadFile } from "@/utils/request";
import { common } from "@/common/mixin";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const { activityTypeList } = useActivity()
    const state = reactive({
      query: {},
      page: 1,
      size: 10,
      total: 0,
      tableDataList: [],
      params: [
        {
          type: "Input",
          key: "name",
          value: "",
          label: "活动名称",
        },
        {
          type: "Select",
          key: "type",
          value: "",
          label: "活动类型",
          options: {
            data: activityTypeList,
            label: "name",
            value: "name"
          }
        },
        {
          type: "Input",
          key: "saleName",
          value: "",
          label: "销售人员",
        },
        {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "日期",
          options: {
            "type": "daterange",
            "format": "YYYY-MM-DD",
            "value-format": "YYYY-MM-DD",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期"
          }
        }, 
      ]
    })
    const getData = async () => {
      try {
        const res = await request("getSalePageList", {
          ...state.query,
          page: state.page,
          size: state.size,
        })
        state.tableDataList = res.records;
        state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = async (data) => {
      state.query = data
      if (data.dateRange && data.dateRange.length>0) {
        state.query.startTime = data.dateRange[0]
        state.query.endTime = data.dateRange[1]
      } else {
        state.query.startTime = ''
        state.query.endTime = ''
      }
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      getData()
    }
    const exportData = async () => {
      try {
        const res = await downloadFile("exportSalePageList", {}, {
          method: 'GET',
          params: state.query
        });
        // let blob = new Blob([res],{type: "application/octet-stream"})
        let reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '销售收集记录.xlsx'
          a.href = e.target.result
          a.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
    getData();
    return {
      ...toRefs(state),
      getData,
      searchData,
      updatePage,
      exportData
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
