<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <el-table-column prop="createTime" label="创建时间" width="150" />
      <el-table-column prop="updateTime" label="修改时间" width="150" />
      <el-table-column prop="creator" label="创建人" />
      <el-table-column prop="type" label="活动类型" />
      <el-table-column prop="activityName" label="活动名称" />
      <el-table-column prop="startDate" label="开始时间" width="150" />
      <el-table-column prop="endDate" label="结束时间" width="150" />
      <el-table-column label="二维码" >
        <template #default="{row}">
          <el-button type="text" size="small" @click="downloadWXAcode(row)">下载</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="客户名称" />
    </Table>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
// import { ElMessage } from "element-plus";

import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import useActivity from './useActivity';

import { request } from "@/utils/request";
import { common } from "@/common/mixin";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const { activityTypeList } = useActivity()
    const state = reactive({
      params: [
        {
          type: "Input",
          key: "activityName",
          value: "",
          label: "活动名称",
        },    
        {
          type: "Select",
          key: "type",
          value: "",
          label: "活动类型",
          options: {
            data: activityTypeList,
            label: "name",
            value: "name"
          }
        },
        {
          type: "Input",
          key: "customerName",
          value: "",
          label: "客户名称",
        },
        {
          type: "Date",
          key: "dateRange",
          value: [],
          label: "日期",
          options: {
            "type": "daterange",
            "format": "YYYY-MM-DD",
            "value-format": "YYYY-MM-DD",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期"
          }
        },
      ],
      query: {},
      page: 1,
      size: 10,
      total: 0,
      dialogFormVisible: false,
      tableDataList: [],
      activityType: activityTypeList // 活动类型列表
    })
    const getData = async () => {
      try {
        const res = await request("unionActivityList", {
          ...state.query,
          page: state.page,
          size: state.size,
        });
        state.tableDataList = res.records;
        state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    }
    const searchData = async (data) => {
      state.query = data
      if (data.dateRange && data.dateRange.length>0) {
        state.query.startDate = data.dateRange[0]
        state.query.endDate = data.dateRange[1]
      } else {
        state.query.startDate = ''
        state.query.endDate = ''
      }
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      getData()
    }
    const downloadWXAcode = (row) => {
      let a = document.createElement('a')
      a.download = row.activityName + '-' + row.customerName
      a.href = 'data:image/jpeg;base64,' + row.qrCode
      a.click();
    }
    getData();
    return {
      ...toRefs(state),
      getData,
      searchData,
      updatePage,
      downloadWXAcode
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
