<template>
  <div class="page-container">
    <el-card class="box-card" :body-style="{ flex: 1, overflow: 'auto' }">
      <template #header>
        <div class="card-header">
          <span>活动编辑</span>
          <div>
            <el-button class="button" type="danger" size="small" @click="cancelEdit">返回</el-button>
            <el-button v-if="true || detailform.status!=='已结束'" class="button" type="primary" size="small" @click="saveEdit">保存</el-button>
          </div>
        </div>
      </template>
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="120px">
        <el-form-item label="标题名称：" prop="name">
          <el-input v-if="newType" v-model="detailform.name" clearable placeholder="标题名称"></el-input>
          <span v-else>{{detailform.name}}</span>
        </el-form-item>
        <el-form-item label="地点：">
          {{detailform.location}}
        </el-form-item>
        <el-form-item label="日期：">
          {{detailform.startDate + ' 至 ' + detailform.endDate}}
        </el-form-item>
        <el-form-item label="时间：" prop="startTime">
          <!-- <div>
            <el-button type="text" size="small" @click="addActivityDate">添加日期时间</el-button>
          </div> -->
          <el-space v-for="(item,index) in activityDateTime" :key="index" style="margin:5px 0">
            <!-- <el-date-picker
              v-model="item.date"
              type="daterange"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker> -->
            <el-time-picker
              v-model="item.time"
              is-range
              format="HH:mm:ss"
              value-format="HH:mm:ss"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              clearable
            >
            </el-time-picker>
            <!-- <i class="el-icon-close" style="cursor: pointer;padding:12px;" @click="deleteActivityDate(index)"></i> -->
          </el-space>
        </el-form-item>
        <el-form-item label="前端是否展示：" prop="isShow">
          <el-switch v-model="detailform.isShow" active-text="全部可见" inactive-text="内部可见" style="margin-right: 10px;" />
        </el-form-item>
        <el-form-item label="活动列表图：" prop="icon">
          <Upload
            v-model="detailform.icon"
            list-type="picture-card"
            type="image"
            :limit="1"
            :auto-upload="true"
            :before-upload="beforeUpload"
            :file-list="iconList"
          >
            <el-button type="text">点击上传图片</el-button>
            <template #tip>
              <div class="el-upload__tip">
                数量1张，大小不超过 500KB，
                <span style="color:red;">图片尺寸：300*260</span>
              </div>
            </template>
          </Upload>
        </el-form-item>
        <el-form-item label="活动标题图：" prop="picture">
          <Upload
            v-model="detailform.picture"
            list-type="picture-card"
            type="image"
            :auto-upload="true"
            :before-upload="beforeUpload"
            :file-list="pictureList"
          >
            <el-button type="text">点击上传图片</el-button>
            <template #tip>
              <div class="el-upload__tip">
                数量1张，大小不超过 500KB，
                <span style="color:red;">图片尺寸：660*360</span>
              </div>
            </template>
          </Upload>
        </el-form-item>
        <el-form-item label="活动内容：" prop="content">
          <div class="el-editor" style="">
            <Editor ref="activeEditor" v-model="detailform.content" />
          </div>
        </el-form-item>
        <el-form-item label="外部链接地址：" prop="content">
          <el-input v-model="detailform.externalLink" clearable placeholder="外部链接地址"></el-input>
        </el-form-item>
        <el-form-item label="外部链接文案：" prop="content">
          <el-input v-model="detailform.externalLinkContent" clearable placeholder="外部链接文案"></el-input>
        </el-form-item>
        <template v-if="activityType == '品牌活动'">
          <el-form-item label="裂变海报：" prop="poster">
            <Upload
              v-model="detailform.poster"
              list-type="picture-card"
              type="image"
              :auto-upload="true"
              :before-upload="beforeUpload"
              :file-list="posterList"
            >
              <el-button type="text">点击上传图片</el-button>
              <template #tip>
                <div class="el-upload__tip">
                  数量1张，大小不超过 500KB
                </div>
              </template>
            </Upload>
          </el-form-item>
          <el-form-item label="客户" >
            <el-select v-model="detailform.unionCustomers" placeholder="客户" :remote-method="companyQuery" multiple filterable remote clearable>
              <el-option
                v-for="(item) in companyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联合活动图：">
            <Upload
              v-model="detailform.unionPicture"
              list-type="picture-card"
              type="image"
              :auto-upload="true"
              :before-upload="beforeUpload"
              :file-list="unionPictureList"
            >
              <el-button type="text">点击上传图片</el-button>
              <template #tip>
                <div class="el-upload__tip">
                  数量1张，大小不超过 500KB
                </div>
              </template>
            </Upload>
          </el-form-item>
        </template>
        <template v-if="activityType == '数字化营销'">
          <el-form-item label="是否分享他人：" prop="isShare">
            <el-radio-group v-model="detailform.isShare" >
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="显示按钮：" prop="buttonContent">
            <template v-for="(item, index) in buttons" :key="index">
              <div style="display: flex;align-items: center;">
                <el-input style="margin: 0 10px 10px 0;" v-model="item.name" clearable placeholder="按钮名称"></el-input>
                <el-input style="margin: 0 10px 10px 0;" v-model="item.link" clearable placeholder="按钮链接"></el-input>
                <i class="el-icon-delete" style="padding: 0 10px;" @click="deleteBtn(index)"></i>
              </div>
            </template>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="addNewBtn">添加按钮</el-button>
          </el-form-item>
        </template>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs, watch } from "vue"
import { useRouter, useRoute } from 'vue-router'

import { ElMessage } from "element-plus";
// import { formatTime } from "../../common/utils";
import Editor from '@/components/editor'
import { urlMap } from '@/common/config';
import { uploadFile } from "@/utils/request";
import Upload from '@/components/upload'

export default {
  components: {
    Editor,
    Upload
  },
  mixins: [common],
  setup() {
    const newTypeList = ['新机上线','积分兑换', '数字化营销']
    const router = useRouter()
    const route = useRoute()
    const activityId = route.query.id
    const activityType = route.query.type
    const state = reactive({
      newType: newTypeList.includes(activityType),
      activeEditor: null,
      detailData: {},
      detailformRef: null,
      detailform: {
        id: activityId,
        name: '',
        // type: '',
        picture: '',
        icon: '',
        location: '',
        startDate: '',
        endDate: '',
        content: '', // 内容
        externalLink: '', // 外部链接地址
        externalLinkContent: '', // 外部链接文案
        poster: '', // 海报
        unionCustomers: [],
        unionPicture: '',
        status: '',
        isShow: '',
        isShare: '', // 显示分享
        buttonContent: '', // 显示按钮
      },
      buttons: [{
        name: '',
        link: '',
      }],
      activityDateTime: [{
        date: [],
        time: []
      }],
      upload: null,
      uploadRef: null,
      iconList: [],
      pictureList: [],
      posterList: [],
      unionPictureList: [],
      uploadFile: urlMap['uploadFile'],
      companyList: []
    })

    const detailformRules = reactive({
      name: {
        required: true,
        message: '活动标题不能为空',
        trigger: 'blur',
      },
      startTime: {
        required: true,
        message: '活动时间不能为空',
        trigger: 'blur',
      },
      icon: {
        required: true,
        message: '活动列表图不能为空',
        trigger: 'blur',
      },
      picture: {
        required: true,
        message: '活动标题图不能为空',
        trigger: 'blur',
      },
      content: {
        required: true,
        message: '活动内容不能为空',
        trigger: 'blur',
      },
      isShare: {
        required: true,
        message: '请选择是否分享他人',
        trigger: 'blur',
      },
      buttonContent: {
        // required: true,
        message: '请输入按钮名称',
        trigger: 'blur',
        validator: (rule, value, callback) => {
          for(let btn of state.buttons) {
            if (!btn.name) {
              callback('请输入按钮名称')
              return
            }
          }
          callback()
        }
      },
    })
    watch(
      () => state.activityDateTime, 
      (cur, pre) => {
        console.log("新值:", cur, "老值:", pre);
        if (state.activityDateTime[0]) {
          // state.detailform.startDate = state.activityDateTime[0].date[0]
          // state.detailform.endDate = state.activityDateTime[0].date[1]
          state.detailform.startTime = state.activityDateTime[0].time[0]
          state.detailform.endTime = state.activityDateTime[0].time[1]
        }
      },
      { deep: true }
    )
    watch(
      () => state.buttons, 
      (cur) => {
        state.detailform.buttonContent = JSON.stringify(cur)
        console.log('buttonContent', state.detailform.buttonContent)
      },
      { deep: true }
    )

    const getData = async () => {
      try {
        const res = await request("getActivityDetail", {
          id: activityId
        })
        state.detailData = res
        console.log('getActivityDetail', res)
        // console.log('activityDateTime', state.activityDateTime)
        state.activityDateTime[0] = {
          date: res.startDate && res.endDate ? [res.startDate, res.endDate]:[],
          time: res.startTime && res.endTime ? [res.startTime, res.endTime]:[]
        }
        state.companyList = res.unionCustomers || []
        res.unionCustomers = state.companyList.map((item) => {
          return item.id
        })
        for (let key of Object.keys(state.detailform)) {
          state.detailform[key] = res[key]
        }
        state.iconList = state.detailform.icon ? [{name: 'icon', url: state.detailform.icon}]:[]
        state.pictureList = state.detailform.picture ? [{name: 'picture', url: state.detailform.picture}]:[]
        state.posterList = state.detailform.poster ? [{name: 'poster', url: state.detailform.poster}]:[]
        state.unionPictureList = state.detailform.unionPicture ? [{name: 'unionPicture', url: state.detailform.unionPicture}]:[]
        state.buttons = state.detailform.buttonContent ? JSON.parse(state.detailform.buttonContent) : []
      } catch (error) {
        console.error(error)
      }
    }
    const companyQuery = async (name) => {
      try {
        if (!name) return
        const res = await request('getCustomerList', {
          customerName: name
        })
        state.companyList = res.slice(0, 30)
      } catch (error) {
        console.error(error)
      }
    }
    const addActivityDate = () => {
      state.activityDateTime.push({})
    }
    const deleteActivityDate = (index) => {
      state.activityDateTime.splice(index,1)
    }
    const beforeUpload = (file) => {
      if (file.size > 500000) {
        ElMessage({
          showClose: true,
          type: "warning",
          message: "图片不能大于500KB"
        })
        return false
      } else {
        return true
      }
    }
    const deleteBtn = (index) => {
      state.buttons.splice(index, 1)
    }
    const addNewBtn = () => {
      state.buttons.push({
        name: '',
        link: '',
      })
    }
    const saveEdit = async () => {
      console.log(state.detailform)
      try {
        state.detailformRef && await state.detailformRef.validate()
        console.log('saveEdit validate -->')
        await request('editActivity', state.detailform)
        ElMessage({
          showClose: true,
          type: "success",
          message: "保存成功!"
        })
        router.back()
      } catch (err) {
        console.error(err)
      }
    }
    const cancelEdit = () => {
      router.back()
      // state.activeEditor.getContent()
    }
    function uploadImg (params) {
      console.log('uploadImg', params)
      const formData = new FormData();
      formData.append('multipartFile', params.file)
      uploadFile(formData)
      .then((res) => {
        console.log('images_success', res)
        
      })
      .catch((err) => {
        console.log('images_err', err)
        
      })
    }
    getData()
    return {
      activityId,
      activityType,
      detailformRules,
      getData,
      ...toRefs(state),
      companyQuery,
      deleteBtn,
      addNewBtn,
      saveEdit,
      cancelEdit,
      beforeUpload,
      uploadImg,
      addActivityDate,
      deleteActivityDate
    };
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.box-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 10;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>