<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column type="index" align="center" label="序号" width="60px" />
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column prop="userName" label="姓名" />
      <el-table-column prop="phoneNumber" label="手机号">
        <template #default="{row}">
          {{ row.phoneNumber &&  (String(row.phoneNumber).slice(0,3)+'****'+String(row.phoneNumber).slice(7))}}
        </template>
      </el-table-column>
      <el-table-column prop="scoreType" label="奖励来源" />
      <el-table-column prop="score" label="积分" />
    </Table>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "@vue/reactivity";
// import { ElMessage } from "element-plus";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      query: {},
      page: 1,
      size: 10,
      total: 0,
      dialogFormVisible: false,
      tableDataList: [],
      detailform: {
        robotModel: '',
        userName: '',
        email: '',
        phoneNumber: '',
        company: '',
        industry: ''
      },
      params: [
        {
          type: "Input",
          key: "userName",
          value: "",
          label: "姓名",
        },
        {
          type: "Input",
          key: "phoneNumber",
          value: "",
          label: "手机号",
        }
      ]
    })
    const getData = async () => {
      try {
        const res = await request("getScoreList", {
          ...state.query,
          page: state.page,
          size: state.size,
        });
        console.log("getScoreList", res);
        state.tableDataList = res.records;
        state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const searchData = async (data) => {
      state.query = data
      getData()
    }
    const updatePage = (page, size) => {
      state.page = page || 1
      state.size = size || 10
      getData()
    }
    getData();
    return {
      ...toRefs(state),
      getData,
      searchData,
      updatePage
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>
